import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreateService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) { }

  createClient(client) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveProvider, client).pipe(map(data => {
      return data;
    }));
  }
  createInboundClient(client) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveInboundProvider, client).pipe(map(data => {
      return data;
    }));
  }
  createDoctor(client) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createDoctor, client).pipe(map(data => {
      return data;
    }));
  }
  updateClient(client) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.insuranceProvidersUpdate, client).pipe(map(data => {
      return data;
    }));
  }
  updateInboundClient(client) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.InboundInsuranceProvidersUpdate, client).pipe(map(data => {
      return data;
    }));
  }
  updateDoctor(client) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDoctor, client).pipe(map(data => {
      return data;
    }));
  }
  
  createScheme(scheme) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createPolicy, scheme).pipe(map(data => {
      return data;
    }));
  }
  createPlan(plan) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createPlan, plan).pipe(map(data => {
      return data;
    }));
  }

  updatePolicy(scheme) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatePolicy, scheme).pipe(map(data => {
      return data;
    }));
  }

  updatePlan(plan) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatePlan, plan).pipe(map(data => {
      return data;
    }));
  }

  
}
