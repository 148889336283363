import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginssoComponent } from './loginsso/loginsso.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { AuthGuard } from './services/auth-gurad.service';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { DisplayDocComponent } from './agent/display-doc/display-doc.component';
import { DisplayDocSaxComponent } from './agent/display-doc-sax/display-doc-sax.component';
import { CSATComponent } from './csat/csat.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent },
  { path: 'loginsso', component: LoginssoComponent },  
  { path: 'resetpassword/:id1/:id2', component: ResetpasswordComponent },
  { path:'upload-documents/:claimId/:travelCaseRef', component:UploadDocumentsComponent},
  { path: 'agent', loadChildren: './agent/agent.module#AgentModule', canLoad: [AuthGuard] },
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule', canLoad: [AuthGuard]},
  { path: 'displayDoc', component: DisplayDocComponent},
  { path: 'displayDocSax', component: DisplayDocSaxComponent},
  { path: 'csat/:encryptedData', component: CSATComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
