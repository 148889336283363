import { Component, OnInit } from '@angular/core';
import { ClaimsService } from 'src/app/services/claims.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router, NavigationEnd } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-open-cases-view',
  templateUrl: './open-cases-view.component.html',
  styleUrls: ['./open-cases-view.component.scss']
})
export class OpenCasesDetailsViewComponent implements OnInit {
  claimAlerts: any[] = [];
  totalItems: number = 0;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  constructor(
    public bsModalRef: BsModalRef,
    private claimsService: ClaimsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.fetchOpenCases();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeModal();
      }
    });
  }

  fetchOpenCases() {
    this.claimsService.getAllOpenCases().subscribe((data: any) => {
      console.log('Data from backend:', data);
      this.claimAlerts = data.data;
      this.totalItems = data.count;
    });
  }

  getCases(page: number) {
    this.claimsService.getNewOpenCases({ page, itemsPerPage: this.itemsPerPage }).subscribe((data: any) => {
      console.log('New cases:', data);
      this.claimAlerts = data;
    });
  }
  downloadExcel(): void {
 
    const desiredFields: string[] = ['claimReported', 'claimId', 'InsuranceProvider', 'policyNumber', 'certificateNumber', 'creationDate', 'FullName'];

 
    const filteredClaims = this.claimAlerts.map(claim => {
        const filteredClaim: any = {};
        desiredFields.forEach(field => {
            filteredClaim[field] = claim[field];
        });
        return filteredClaim;
    });

    const header: string[] = desiredFields;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredClaims);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Open Cases');
    XLSX.writeFile(wb, 'open_cases.xlsx');
}

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
