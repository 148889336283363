import { Component, OnInit, ComponentRef } from '@angular/core';
import { InboundLogService } from 'src/app/services/inbound-log.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
declare const $: any;

@Component({
  selector: 'app-payout-transactions',
  templateUrl: './payout-transactions.component.html',
  styleUrls: ['./payout-transactions.component.scss']
})
export class PayoutTransactionsComponent implements OnInit {
  title: string;
  closeBtnName: string;
  data: any;
  claimsList: any;
  customerData: any;
  customerName: any;
  payoutForm: FormGroup;
  submitted: Boolean = false
  dateCustomClasses: DatepickerDateCustomClasses[];
  createClaimDate:any;
  todayDate: any = new Date()
  payoutTransactions: any = []
  isUpdate:boolean = false
  updatedId: any;
  isCardHolder:Boolean = false
  previousData:any
  
  ngOnInit() {    
    this.getPayoutTransactions();
    this.dateCustomClasses = [
      { date: new Date(), classes: ['text-warning'] },
    ];
    this.payoutForm = this.formBuilder.group({
      paymentTypes: ['', Validators.required],
      cardHolderName: [''],
      vendorName:['',Validators.required],
      transactionId:['',[Validators.required]],
      transactionDate: ['', [Validators.required]],
      amountPaid:['',Validators.required],
      modeofPayment: ['', Validators.required],
      transactionDetails: ['', Validators.required],
      remarks: ['', Validators.required]
    })  
  }
  
  get f() { return this.payoutForm.controls; }
  
  constructor(private toastr: ToastrService, public bsModalRef: BsModalRef, private formBuilder: FormBuilder, public inboundLogService: InboundLogService) {}
  
  saveRecord() {
    this.submitted = true
    if (this.payoutForm.invalid) {
      return;
    }
    let formValues = this.payoutForm.value
    formValues.transactionDate = this.myDateFormatFunction(formValues.transactionDate)
    formValues.transactionDate = this.myDateFormatFunction(formValues.transactionDate)
    formValues.customerId = this.data.customerId
    formValues.caseId = this.data.caseId
    formValues.caseNumber = this.data.caseNumber
    formValues.clientObjId = this.data.clientObjId
    formValues.clientId = this.data.clientId
    formValues.clientName = this.data.clientName
    this.inboundLogService.createPayoutTransactions(formValues).subscribe((data:any) => {
      this.submitted = false
      this.toastr.success("Record saved successfully");
      this.payoutTransactions = data
      this.resetForm()
      this.getPayoutTransactions();
    })
  }
  resetForm() {
    this.payoutForm.reset()
    this.payoutForm.get('paymentTypes').setValue("");
    this.payoutForm.get('modeofPayment').setValue("");
    this.isUpdate = false
    this.isCardHolder = false
  }
  edit(id) {
    this.updatedId = id._id
    this.isUpdate = true
    $("#nav-profile-tab").addClass('active')
    $("#nav-profile").addClass('active show')
    $("#nav-inbound-tab").removeClass('active')
    $("#nav-inbound").removeClass('active show')
    this.payoutForm.get('paymentTypes').setValue(id.paymentTypes);
    this.payoutForm.get('cardHolderName').setValue(id.cardHolderName);
    this.payoutForm.get('vendorName').setValue(id.vendorName);
    this.payoutForm.get('transactionId').setValue(id.transactionId);
    this.payoutForm.get('transactionDate').setValue(id.transactionDate);
    this.payoutForm.get('amountPaid').setValue(id.amountPaid);
    this.payoutForm.get('modeofPayment').setValue(id.modeofPayment);
    this.payoutForm.get('transactionDetails').setValue(id.transactionDetails);
    this.payoutForm.get('remarks').setValue(id.remarks);
    this.previousData = id,
    this.previousData['customerId'] = this.data.customerId
    this.previousData['caseId'] = this.data.caseId
    this.previousData['caseNumber'] = this.data.caseNumber
    this.previousData['clientObjId'] = this.data.clientObjId
    this.previousData['clientId'] = this.data.clientId
    this.previousData['clientName'] = this.data.clientName
  }

  updateRecord() {
    this.submitted = true
    if (this.payoutForm.invalid) {
      return;
    }
    let formValues = this.payoutForm.value
    formValues.transactionDate = this.myDateFormatFunction(formValues.transactionDate)
    formValues.transactionDate = this.myDateFormatFunction(formValues.transactionDate)
    formValues.customerId = this.data.customerId
    formValues.caseId = this.data.caseId
    formValues.caseNumber = this.data.caseNumber
    formValues.clientObjId = this.data.clientObjId
    formValues.clientId = this.data.clientId
    formValues.clientName = this.data.clientName
    formValues._id = this.updatedId
    formValues.previousData = this.previousData
    this.inboundLogService.updatePayoutTransactions(formValues).subscribe((data:any) => {
      $("#nav-inbound-tab").addClass('active')
      $("#nav-inbound").addClass('active show')
      $("#nav-profile-tab").removeClass('active')
      $("#nav-profile").removeClass('active show')
      this.submitted = false
      this.toastr.success("Record updated successfully");
      // this.payoutTransactions = data
      this.resetForm()
      this.getPayoutTransactions();
    })
  }
  
  delete(id) {
    let data = {
      id: id
    }
    if(confirm("Do you want to delete ?")) {
      this.inboundLogService.deletePayoutTransactions(data).subscribe(data => {
        this.getPayoutTransactions();
        this.toastr.success("Record deleted successfully");
      })
    }
  }

  getPayoutTransactions() {
    this.inboundLogService.getPayoutTransactions(this.data.caseId).subscribe((data:any) => {
      this.payoutTransactions = data
    })
  }

  datePickerValidations(event,data) {
    if(event.target.value.length >= 11) {
      return false
    }
    if(event.target.selectionStart === 2 || event.target.selectionStart === 6) {
      if(event.key == '/') {
        return true
      }
      else {
        return false
      }
    }
    if(event.target.selectionStart >= 3 && event.target.selectionStart <= 5) {
      if ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8) {
            return true;
      }
      else {
        return false;
      }
    }
    if((event.target.value.length >= 0 && event.target.value.length <= 1) || (event.target.value.length >= 7 && event.target.value.length <= 11) ) {
      let patt = /^([0-9])$/;
      let result = patt.test(event.key);
      return result;
    }
  }
  myDateFormatFunction(inputDate) {
    let d = new Date(inputDate)
    return d.getFullYear() + '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
  }
  getField(event){
    if(event === "Credit Card" || event === "Personal Card"){
      this.isCardHolder = true
    }
  }
}
