import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClaimsService } from '../services/claims.service';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../services/master.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {

  public claimId:string;
  public travelCaseRef:string;
  public claimDetails: any;
  public documentType: string;
  public  uploadedFile: File;
  public documentList: any = [];
  public currentDocument: any;
  modalRef: BsModalRef;
  filePathurl = environment.authURL + environment.apiEndPoints.filePath;
  @ViewChild('inputFile') myInputVariable: ElementRef;
  @ViewChild('docUploadTypeDropdown') docTypeDropdown: ElementRef;
  
  constructor(private route: ActivatedRoute,
     private claimsService: ClaimsService, private toastr: ToastrService,
     private masterService: MasterService, private authService: AuthService ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.claimId = params['claimId'];
      this.travelCaseRef = params['travelCaseRef'];
    });
    this.getCustomerJwt();
     
  }

  changeDocumentType(event: any) {
    this.documentType = event.target.value;
  }

  getDocumentList(claimType: String) {
    this.masterService.getDocumentsList(claimType).subscribe((data: any) => {
      if (data.length)
        this.documentList = data[0].Documents;
    });
  }



  uplaodDocument() {
    if (this.documentType && this.uploadedFile) {
      this.claimsService.uploadDocuments(this.uploadedFile, this.documentType, this.travelCaseRef, '')
        .subscribe((result: any) => {}, (error: any) => {
            if (error.status == 200) {
              this.getClaimDetails();
              this.toastr.success("Document uploaded successfully.");
              this.myInputVariable.nativeElement.value = '';
              this.docTypeDropdown.nativeElement.value = '';
              this.uploadedFile = undefined;
            }
          }
        );

    } else {
      this.toastr.warning("Please select documet type and file.");
      return false;
    }
  }

  fileChangeEvent(fileInput: any) {
    let uploadFile  = fileInput.target.files[0];   
    if(uploadFile.size > 2000000){
      alert("file size cannot be more than 2MB");  
      this.myInputVariable.nativeElement.value = '';        
    }else{
      this.uploadedFile = fileInput.target.files[0];
    }   
  }


  setCurrentDocument(document: any) {
    this.currentDocument=document;
      if(confirm("Are you sure to delete ")) {
        this.deleteDocument();
        console.log("Implement delete functionality here");
      }
   
  }

  deleteDocument() {
    this.claimsService.travelCaseDeleteDoc(this.currentDocument.documentName,  this.travelCaseRef, '')
      .subscribe((result: any) => {
        this.getClaimDetails();
        this.toastr.success("Document deleted successfully.");
        this.modalRef.hide();
      });
 
}

  getClaimDetails() {    
    this.claimsService.getClaim(this.claimId).subscribe((data: any) => {    
      if (data.length) {
        if(data[0].travelCaseRef ==  this.travelCaseRef){
        this.claimDetails = data[0];    
        if(this.claimDetails.travelCases.claimType){
          this.getDocumentList(this.claimDetails.travelCases.claimType);  
        }   
      }             
      }
    });
  }


  getCustomerJwt(){
    this.authService.getCustomerJwt().subscribe((data: any)=>{
      sessionStorage.setItem('token', data);
      this.getClaimDetails(); 
    });
  }
}
