import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MasterService } from '../../services/master.service';
import { saveAs } from 'file-saver';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Claim, UpdateClaimForm, updatePayable } from 'src/app/_shared/models/claim.model';
import { Title } from '@angular/platform-browser'
@Component({
  selector: 'app-display-doc',
  templateUrl: './display-doc.component.html',
  styleUrls: ['./display-doc.component.scss'],
  providers: [Claim, UpdateClaimForm, updatePayable, DatePipe, PdfViewerComponent]
})
export class DisplayDocComponent implements OnInit {

  pdfSrc:string;
  showImage:any;
  title=""
  pageView="updateview";
  src = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  fileName:any;
  filePathurl = environment.authURL + environment.apiEndPoints.filePath;
  constructor(private route :ActivatedRoute , private masterService:MasterService , private titleService:Title , private render:Renderer2) { }

  ngOnInit(): void {
    const link :HTMLLinkElement=this.render.createElement('link');
    link.rel='icon';
    link.type='image/x-icon';
    link.href='../../../assets/img/pngegg.png';
    const existingFavicon = document.querySelector("link[rel='icon']")
    if(existingFavicon){
      this.render.removeChild(document.head , existingFavicon);
    }
    this.render.appendChild(document.head,link)
    
    this.route.queryParams.subscribe(params=>{
      this.fileName=params.id;
      let lastFour = this.fileName.substr(this.fileName.length - 4);
      if(lastFour == '.jpg' || lastFour == '.JPG' || lastFour == '.png' || lastFour == '.PNG' || lastFour == 'jpeg' || lastFour == 'JPEG'){
        this.pageView="imageview";
        this.pdfSrc=this.filePathurl+this.fileName
        this.titleService.setTitle("Image")
      }else if(lastFour=='.pdf' || lastFour == '.PDF'){
        
        this.pageView='pdf'
        if (params.otherComponent) {
          this.pdfSrc = this.filePathurl + this.fileName + `&fromOtherComponent=${params.otherComponent}`;
      } else {
          this.pdfSrc = this.filePathurl + this.fileName;
      }
        this.titleService.setTitle("PDF")
      }else{
        
        this.pageView='otherFormat';
      }
      
    })
  }
  downloadFile(){
    this.masterService.downloadFile(this.filePathurl+this.fileName).subscribe(
      data=>saveAs(data,this.fileName),
      error=>console.error(error)
    )
  }
}
