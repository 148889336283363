import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Customer, CustomerSearch } from '../_shared/models/claim.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) { }
  getCustomerDetails(customerId: string,policyid:string) {    
    return this.http.get(this.baseUrl + environment.apiEndPoints.customerDetails + '?customerId=' + customerId+'&policyNumber='+policyid).pipe(map(data => {
      return data;
    }));
  }

  getCustomerWithClaims(customerId: string) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.customerWithClaimDetails + '?customerId=' + customerId).pipe(map(data => {
      return data;
    }));
  }
  getAndUpdateVB64(dataPolicy: any) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getAndUpdateVB64, dataPolicy).pipe(map(data => {
      return data;
    }));
  }

  saveCustomer(customer: Customer) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveCustomer, customer).pipe(map(data => {
      return data;
    }));
  }
  updateInboundCustomer(customer: Customer) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateInboundCustomer, customer).pipe(map(data => {
      return data;
    }));
  }
  inboundCustomer(customer: Customer) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.inboundCustomer, customer).pipe(map(data => {
      return data;
    }));
  }

  createCustomerAssignPolicy(customer: Customer) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createCustomerAssignPolicy, customer).pipe(map(data => {
      return data;
    }));
  }

  policySearch(client, policyNUmber) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.searchToAssign+ '?insuranceProvider=' + client+'&policyNumber='+policyNUmber).pipe(map(data => {
      return data;
    }));
  }

  updateCustomerWithPolicy(customer: Customer, cusid, policyid, caseid) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateCustomerWithPolicy+ '?cusid=' + cusid+'&policyid='+policyid+'&caseid='+caseid, customer).pipe(map(data => {
      return data;
    }));
  }
  
  getCustomers(customer: CustomerSearch, page, itemsPerPage) {
    console.log(customer, 'customer at begining')
    let queryParams: string = '';
    if (customer) {
      if (customer.pls_Customer_Id)
        queryParams += 'Pls_Customer_Id=' + customer.pls_Customer_Id;
      if (customer.contactEmail)
        queryParams += '&contactEmail=' + customer.contactEmail;
      if (customer.firstName)
        queryParams += '&FirstName=' + customer.firstName;
      if (customer.dateOfBirth)
        queryParams += '&DateOfBirth=' + customer.dateOfBirth;
      if (customer.passportNumber)
        queryParams += '&PassportNumber=' + customer.passportNumber;
        
      console.log(customer, 'customer')
      console.log(queryParams, 'query params')

        let body: any = {};
        body.page = page;
        body.itemsPerPage = itemsPerPage;
        body.Pls_Customer_Id = customer['Pls_Customer_Id']
        body.contactEmail = customer['contactEmail']
        body.DateOfBirth = customer['DateOfBirth']
        body.FirstName = customer['FirstName']
        body.LastName = customer['LastName']                
        body.passportNumber = customer['passportNumber']
      
      console.log(body, 'this is the data')
      return this.http.post(this.baseUrl + environment.apiEndPoints.getCustomers,body).pipe(map(data => {
        console.log(data, 'data in service');
        return data;
      }));
    }
  }
  getCustomersSearch(customer: CustomerSearch, page, itemsPerPage) {
    console.log(customer, 'customer at begining')
    let queryParams: string = '';
    if (customer) {
      if (customer.pls_Customer_Id)
        queryParams += 'Pls_Customer_Id=' + customer.pls_Customer_Id;
      if (customer.contactEmail)
        queryParams += '&contactEmail=' + customer.contactEmail;
      if (customer.firstName)
        queryParams += '&FirstName=' + customer.firstName;
      if (customer.dateOfBirth)
        queryParams += '&DateOfBirth=' + customer.dateOfBirth;
      if (customer.passportNumber)
        queryParams += '&PassportNumber=' + customer.passportNumber;
        
      console.log(customer, 'customer')
      console.log(queryParams, 'query params')

        let body: any = {};
        body.page = page;
        body.itemsPerPage = itemsPerPage;
        body.Pls_Customer_Id = customer['Pls_Customer_Id']
        body.contactEmail = customer['contactEmail']
        body.DateOfBirth = customer['DateOfBirth']
        body.FirstName = customer['FirstName']
        body.LastName = customer['LastName']                
        body.passportNumber = customer['passportNumber']
      
      console.log(body, 'this is the data')
      return this.http.post(this.baseUrl + environment.apiEndPoints.inboundCustomers,body).pipe(map(data => {
        console.log(data, 'data in service');
        return data;
      }));
    }
  }

}
