import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function AlphaNumericPattern() {
    return new RegExp(/^[a-zA-Z0-9]*$/);
}
export function NumericPattern() {
    return new RegExp(/^[0-9]*$/);
}
export function AlphabetPattern() {
    return new RegExp(/^[a-zA-Z ]*$/);
}
export function MaxDatePattern(controlName: string) {
    return (group: FormGroup): { [key: string]: any } => {
        const control = group.controls[controlName];
        let f = group.controls[controlName];
        let t = new Date();
        if (new Date(f.value) > t) {
            control.setErrors({ maxDatePattern: true });
        } else {
            control.setErrors(null);
        }
        return {};
    }
}
export function DatePattern() {
    return new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);
}

export function AtLeastOneValidator() {
    return (controlGroup: FormGroup) => {
        let controls = controlGroup.controls;
        if (controls) {
            let theOne = Object.keys(controls).find(key => controls[key].value !== '');
            if (!theOne) {
                return {
                    AtLeastOneRequired: {
                        text: 'At least one should be selected'
                    }
                }
            }
        }
        return null;
    };
};