import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UpdateClaim, Claim, Cas, UpdateClaimForm, updatePayable } from '../_shared/models/claim.model'

@Injectable({
  providedIn: 'root'
})
export class InboundLogService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) { }
  saveLogNew(logComment: string, logType: string, channel: string, direction: string, source:string, travelCaseId: string,loggedInUser:string, caseNumber:string) {
    // console.log(caseNumber, 'caseNumber')
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveInboundLog, { comment: logComment, logType: logType,channel:channel,direction:direction,source:source, travelCaseId: travelCaseId, createdBy: loggedInUser, caseNumber: caseNumber}).pipe(map(data => {
      return data;
    }));
  }
  getLogs(page, itemsPerPage, travelCaseId) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getInboundLogs+"?page="+page+"&itemsPerPage="+itemsPerPage+"&travelCaseId="+travelCaseId).pipe(map(data => {
      return data;
    }));
  }
  getLogComment(id:any) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getInboundLogComment + '?_id=' + id).pipe(map(data => {
      return data;
    }));
  }
  saveNewNote(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveInboundNewNote, data).pipe(map(data => {
      return data;
    }));
  }
  getNewNote(data) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getInboundNewNote+"?_id="+data).pipe(map(data => {
      return data;
    }));
  }
  uploadDocuments(file: File, casefileType: string, travelCaseId: string, docComment:string,caseNumber:string ) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      //formData.append('Content-Disposition', file);
      formData.append('imgUploader', file);
      //formData.append('Content-Type', file.type);
      // console.log(file, 'doc type id')
      return this.http.post(this.baseUrl + environment.apiEndPoints.testExample + `?docTypeId=${casefileType}&travelCaseId=${travelCaseId}&docComment=${docComment}&CaseNumber=${caseNumber}`, formData)
        .pipe(map(doc => {
          return doc;
        }));

    }
    else {
      window.alert("Please add a document to upload")
    }
  }
  saveClaimDetails(data){
    // console.log(data)
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveInboundClaimDetails, data).pipe(map(data => {
      return data;
      
    }));
  }
  updateClaimDetails(data){
    {
      return this.http.post(this.baseUrl + environment.apiEndPoints.updateInboundClaimDetails,data).pipe(map(data => {
        return data;
      }));
    
     }
    }
    deleteClaimDetails(data){
      return this.http.post(this.baseUrl + environment.apiEndPoints.deleteInboundClaimDetails,data).pipe(map(data => {
        return data;
      }));
    
     }
     travelCaseDeleteDoc(documentName: string,  travelCaseId: string, comment: string,caseNo:string) {
      let travelCaseDocument = {
        "documentName": documentName,
        "travelCaseId": travelCaseId,
        "comment": comment,
        "CaseNumber":caseNo
      }
      return this.http.post(this.baseUrl + environment.apiEndPoints.deleteInboundDocument, travelCaseDocument)
        .pipe(map(response => {
          return response;
        }));
    }
    getPayoutTransactions(caseId) {
      return this.http.get(this.baseUrl + environment.apiEndPoints.getpayouttransactions+"?caseId="+caseId).pipe(map(data => {
        return data;
      }));
    }
    createPayoutTransactions(data){
      return this.http.post(this.baseUrl + environment.apiEndPoints.createpayouttransactions, data).pipe(map(data => {
        return data;        
      }));
    }
    updatePayoutTransactions(data){
      return this.http.post(this.baseUrl + environment.apiEndPoints.updatepayouttransactions, data).pipe(map(data => {
        return data;        
      }));
    }
    deletePayoutTransactions(data){
      return this.http.post(this.baseUrl + environment.apiEndPoints.deletepayouttransactions, data).pipe(map(data => {
        return data;        
      }));
    }
}
