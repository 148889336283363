import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as cryptoJS from 'crypto-js';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  id1: String;
  userName: string;
  password: string;
  cpassword: string;
  isSignInClick = false;
  isLoginError = false;
  errMessage = ""
  
  encdataToEncrypt: any = {id:"abc123", time:new Date().getTime()}
  encryptedData = "";
  secretKey = "secrmustnew"


  constructor(private authService: AuthService, private router: Router,
    private activatedRoute: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id1 = params['id1'];
      const id2 = params['id2'];
    })
  }

  onSignInClick() {
    this.isSignInClick = true;
    if(this.password == this.cpassword) {
      this.errMessage = ""
      let requestObject = {
        userId: this.id1,
        password: this.password,
      };
      this.authService.resetPassword(requestObject).subscribe(
        (response) => {
          this.toastr.success("Password Changes Succesfully");
          this.router.navigateByUrl('/login')
        },
        (err) => {}
      );
    }
    else {
      this.errMessage = "Password and Confirm Password Does not Match"
    }
    // if (this.userName && this.password) {
    //   this.authService.signIn(this.userName, this.password)
    //     .subscribe((result: any) => {
    //       if (result != null && result.user != null) {
    //         localStorage.setItem('user_name', result.user.user_name);
    //         localStorage.setItem('_id', result.user._id);
    //         localStorage.setItem('token', result.user.token);
    //         localStorage.setItem('email', result.user.email);
    //         localStorage.setItem('role', result.user.role_name);
    //         localStorage.setItem('role_permissions', JSON.stringify(result.user.role_permissions));
    //         let returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl']
    //         console.log(result);
    //         if(result.user.role_name == "admin"){
    //           this.router.navigate(['/admin/users']);
    //         }else{
    //           this.router.navigate(['/agent/policy-search']);
    //         }
    //         // if (returnUrl) {
    //         //   this.router.navigateByUrl(returnUrl);
    //         // } else {
    //         //  // alert("Hello");
    //         //   this.router.navigate(['/agent/policy-search']);
    //         // }
    //       }
    //       else {
    //         //alert("Invalid Credential");
    //         // var toastOptions = this.searchService.setToastOptions('Sign In Errors', 'Invalid Credential', '')
    //         // this.toastaService.error(toastOptions);
    //       }

    //     }, err => {
    //       this.isLoginError=true;
    //     })

    //     ;
    // }
    // else {
      
    // }
  }
  encrypt(value) {
    this.encryptedData = CryptoJS.AES.encrypt(JSON.stringify(this.encdataToEncrypt), this.secretKey).toString()
    console.log(this.encryptedData, 'with special')
    console.log(this.encryptedData, 'with out special')
  }
  decrypt() {
    this.encryptedData.toString().replace('xMl3Jk', '+' ).replace('Por21Ld', '/').replace('Ml32', '=');
    let bytes = CryptoJS.AES.decrypt(this.encryptedData, this.secretKey)
    var obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    console.log(obj.id, 'id');
    console.log(obj.time, 'time');

  }




}
