import { Component, OnInit, ComponentRef } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { SaxService } from 'src/app/services/sax.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customer-details-view-sax',
  templateUrl: './customer-details-view-sax.component.html',
  styleUrls: ['./customer-details-view-sax.component.scss']
})
export class CustomerDetailsViewSaxComponent implements OnInit {
  title: string;
  closeBtnName: string;
  data: any;
  claimsList:any;
  customerData:any;
  ngOnInit() {
    this.saxService.getCustomerWithClaims(this.data.customerId).subscribe((data: any) => {
      this.customerData=data[0];
      this.claimsList=data[0].claims;
      // if(this.claimsList.VB64Confirmation != "Confirmed") {
      //   let dataPolicy = {
      //     "policyid": this.customerData.policy._id,
      //     "certificateNumber" : this.customerData.certificateNumber
      //   }
      //   this.customerService.getAndUpdateVB64(dataPolicy).subscribe((updatedData: any) => {          
      //     let newUpdatedData = JSON.parse(updatedData)
      //     this.customerData.policy.VB64Confirmation = newUpdatedData.PolicyDetails.Table[0].VB64Confirmation
      //   });
      // }
    });
  }
 

  constructor(public bsModalRef: BsModalRef,public customerService : CustomerService, public saxService: SaxService) {}
  }
