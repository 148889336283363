import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UsersService } from './users.service';
import {Location} from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private _location: Location,private authService: AuthService, private router: Router, private userService:UsersService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log('testingggg')
    const isUserAuthenticated = this.authService.isAuthenticated();
    let validateCheck = sessionStorage.getItem('validateCheck');
    // console.log(validateCheck, 'storage element')
    if(validateCheck) {
      this.authService.checkSSOSession(validateCheck).subscribe(       
        (response: any) => {
          if(response.code != 200) {
            sessionStorage.clear();
            this.router.navigate(['/']);  
          }
        },
        (err) => {
          sessionStorage.clear();
          this.router.navigate(['/']);
        }
      )
    }
    else {
      // console.log('did not come')
    }

    const permissions= this.userService.permissionsdata();
    if (permissions[route.data.access]) {
      return true;
    } else {
      this._location.back();
      return false;
    }    
  }

  canActivateChild() {
    // console.log('checking child route access');
    return true;
  }

  canLoad() {
    // console.log('testing')
    const isUserAuthenticated = this.authService.isAuthenticated();
    if (!isUserAuthenticated)
      this.router.navigate(['/']);

      
    return isUserAuthenticated;
  }

}