import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = 'Bearer ' + this.auth.getToken();
    const oAuthToken = this.auth.getOauthToken();
    let authReq;
    if(req.url == environment.ssoURL + environment.apiEndPoints.oAuthValidation) {
      authReq = req.clone({
        setHeaders: {
          Authorization: oAuthToken,
          "Content-Type": "application/json"
        }
      });
    }
    else if(req.url == environment.ssoURL + environment.apiEndPoints.getServerKey) {
      authReq = req.clone({
        setHeaders: {
          Authorization: oAuthToken,
          "Content-Type": "application/json"
        }
      });
    }
    else {
      if (req.url.indexOf(environment.apiEndPoints.uploadDocument) > 0) {
        authReq = req.clone({
          setHeaders: {
            Authorization: authToken,
          }
        });
      } else {
        authReq = req.clone({
          setHeaders: {
            Authorization: authToken,
            "Content-Type": "application/json"
          }
        });
      }
    }
    // send cloned request with header to the next handler.
    return next.handle(authReq);


  }
}
