import { Component, OnInit ,ComponentRef} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PolicyService } from 'src/app/services/policy.service';
import * as CryptoJS from 'crypto-js';
import { MasterService } from 'src/app/services/master.service';



@Component({
  selector: 'app-inbound-customer-view',
  templateUrl: './inbound-customer-view.component.html',
  styleUrls: ['./inbound-customer-view.component.scss']
})
export class InboundCustomerViewComponent implements OnInit {
  title: string;
  closeBtnName: string;
  customerId : any;
// Initialize customerData and claimsList
customerData: any; // You can specify a more specific type if available
claimsList: any[] = []; // An array to store multiple claims

  constructor(public bsModalRef: BsModalRef, public masterService:MasterService) { }

  ngOnInit(): void {
    this.masterService.getCustomerDetailsById(this.customerId )
    .subscribe((inboundResult: any) => {
      this.customerData = inboundResult[0];
      this.claimsList = this.customerData.cases;

    });
    
  }
  encryptCustomerId(customerId: string): string {
    const secretKey = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Replace with your secret key
    const encrypted = CryptoJS.AES.encrypt(customerId, secretKey);
    return encrypted.toString();
  }
  encryptCaseId(caseId: string): string {
    const secretKey = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Replace with your secret key
    const encrypted = CryptoJS.AES.encrypt(caseId, secretKey);
    return encrypted.toString();
  }

}
